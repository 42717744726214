import React, { useEffect, useMemo, useState } from "react"
import { observer } from "mobx-react"
import { Card, BsTable, BsTableLoading, BsTableNoContent, Button } from "@brightsource/brightsource-ui-lib"
import { BsPagination } from "../../../../../app/components/common"
import { processReportStore } from "../../report.store"
import { LoaderInline } from "../../../../../shared/ui/Loader"
import { formatDate } from "../../../../../shared/utils/date.utils"
import data from "../../../../../constants/data"
import utilsService from "../../../../../services/utils.service"
import { Link } from "react-router-dom"
import DefaultImageComponent from "../../../../../app/components/DefaultImage.component"
import Papa from "papaparse"
import "./StatusReportTable.scss"

const formFields = {
  CANDIDATE_NAME: "candidateName",
  JOB_NAME: "jobName",
  COMPANY_NAME: "companyName",
  PROJECT_TYPE: "projectType",
  STAGE: "stage",
  EVENT_DATE: "eventDate",
  JOB_STATUS: "jobStatus",
  CANDIDATE_AVAILABILITY: "candidateAvailability",
  JOB_RECRUITER: "jobRecruiter",
}

export const StatusReportTable = observer(() => {
  const { total, items, isLoading, hasNoContent } = processReportStore
  const {
    limit,
    page,
    companyName,
    jobTitle,
    candidateName,
    jobHandler,
    sender,
    stage,
    jobStatus,
    candidateAvailability,
    lastUpdate,
    jobRecruiter,
  } = processReportStore.properties
  const [hiringManagersData, setHiringManagersData] = useState({})

  const pageCount = Math.ceil(total / limit)

  useEffect(() => {
    const fetchHiringManagers = async () => {
      const hiringManagersPromises = items.map(async (item) => {
        const managers = await utilsService.getHiringManager(item.hiringManagers)
        return { id: item._id, managers }
      })

      const results = await Promise.all(hiringManagersPromises)
      const dataMap = results.reduce((acc, { id, managers }) => {
        acc[id] = managers
        return acc
      }, {})

      setHiringManagersData(dataMap)
    }

    if (items.length > 0) {
      fetchHiringManagers()
    }
  }, [items])

  function getJobStatusLabel(jobStatus) {
    return data.jobStatus.find((status) => status.elId === parseInt(jobStatus, 10))?.value
  }

  const rows = useMemo(() => {
    return items.map((item) => {
      const managers = hiringManagersData[item._id] || []
      return {
        id: item._id,
        [formFields.CANDIDATE_NAME]: (
          <div className='object-card'>
            <div className='object-image'>
              <DefaultImageComponent
                url={utilsService.getProfileImage(item.candidateData)}
                alt={item.candidateName}
                profileName={item.candidateName}
              />
            </div>
            <div className='object-infor'>
              <span className='name'>
                <Link to={`/profile/${item.candidateSlug}`}>{item.candidateName}</Link>
              </span>
              <span className='position'>{item?.candidateData?.position}</span>
            </div>
          </div>
        ),
        [formFields.COMPANY_NAME]: <Link to={`/company/${item.companySlug}`}>{item.companyName}</Link>,
        [formFields.JOB_STATUS]: getJobStatusLabel(item.jobStatus),
        [formFields.JOB_NAME]: <Link to={`/jobs/${item.jobSlug}`}>{item.jobName}</Link>,
        [formFields.STAGE]: utilsService.getStatusByEnum(item.stage),
        [formFields.EVENT_DATE]: formatDate(item.eventDate),
        [formFields.CANDIDATE_AVAILABILITY]: item.candidateAvailability,
        [formFields.JOB_RECRUITER]: (
          <span className='recruiter'>{managers.length > 0 ? managers.join(", ") : "None"}</span>
        ),
      }
    })
  }, [items, hiringManagersData])

  const columns = useMemo(() => {
    return [
      { id: formFields.CANDIDATE_NAME, label: "Candidate name" },
      { id: formFields.COMPANY_NAME, label: "Company name" },
      { id: formFields.JOB_STATUS, label: "Job status" },
      { id: formFields.JOB_NAME, label: "Job title" },
      { id: formFields.STAGE, label: "Stage" },
      { id: formFields.EVENT_DATE, label: "Event date" },
      { id: formFields.CANDIDATE_AVAILABILITY, label: "Candidate availability" },
      { id: formFields.JOB_RECRUITER, label: `Employer's recruiter` },
    ]
  }, [])

  function handlePageChange(selected) {
    processReportStore.updatePage(selected)
  }

  const exportToCSV = () => {
    const filter = {
      companyName,
      jobTitle,
      candidateName,
      jobHandler,
      sender,
      stage,
      jobStatus,
      candidateAvailability,
      lastUpdate,
      jobRecruiter,
      page,
      limit,
    }

    const filterRow = {
      [formFields.CANDIDATE_NAME]: `Candidate Name: ${filter.candidateName || "All"}`,
      [formFields.COMPANY_NAME]: `Company: ${filter.companyName || "All"}`,
      [formFields.JOB_STATUS]: `Job Status: ${getJobStatusLabel(filter.jobStatus) || "Open"}`,
      [formFields.JOB_NAME]: `Job Title: ${filter.jobTitle || "All"}`,
      [formFields.STAGE]: `Stage: ${utilsService.getStatusByEnum(filter.stage) || "All"}`,
      [formFields.EVENT_DATE]: `Last Update: ${filter.lastUpdate || "All"}`,
      [formFields.CANDIDATE_AVAILABILITY]: `Candidate Availability: ${data.availabilityOptionsForCandidates.find(item => item.elId === filter.candidateAvailability.elId).value || "Available"}`,
      [formFields.JOB_RECRUITER]: `Job Recruiter: ${hiringManagersData[filter.jobRecruiter] || "All"}`,
      ['Page']: `Page: ${filter.page}`,
      ['Limit']: `Limit: ${filter.limit}`,
    }

    // Now add the filter row to the top of the CSV data
    const dataForCSV = [
      filterRow, // This is the row that contains the filter information
      ...items.map((item) => {
        const managers = hiringManagersData[item._id] || []
        return {
          [formFields.CANDIDATE_NAME]: item.candidateName,
          [formFields.COMPANY_NAME]: item.companyName,
          [formFields.JOB_STATUS]: data.jobStatus.find((status) => status.elId === parseInt(item.jobStatus, 10))?.value,
          [formFields.JOB_NAME]: item.jobName,
          [formFields.STAGE]: utilsService.getStatusByEnum(item.stage),
          [formFields.EVENT_DATE]: formatDate(item.eventDate),
          [formFields.CANDIDATE_AVAILABILITY]: item.candidateAvailability,
          [formFields.JOB_RECRUITER]: managers.length > 0 ? managers.join(", ") : "None",
        }
      }),
    ]

    // Format the CSV using PapaParse
    const csv = Papa.unparse(dataForCSV)
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" })
    const link = document.createElement("a")
    link.href = URL.createObjectURL(blob)
    link.download = "status_report.csv"
    link.click()
  }

  return (
    <LoaderInline>
      <Card>
        <div className='report-actions'>
          <Button onClick={exportToCSV}>Export to CSV</Button>
        </div>
        <BsTable
          selectable={false}
          defaultOrderBy={formFields.RECRUITER}
          rows={isLoading ? [] : rows}
          columns={columns}
        />
        {hasNoContent && <BsTableNoContent />}
        {isLoading && <BsTableLoading />}
        <BsPagination currentPage={page} onPageChange={handlePageChange} totalPage={pageCount} />
      </Card>
    </LoaderInline>
  )
})
