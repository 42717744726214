import React, {forwardRef, useEffect, useImperativeHandle} from 'react';
import './StatusReportDashboard.scss';
import {ProcessReportTable} from '../../../../../../entities/reports/model/process/ProcessReportTable/ProcessReportTable';
import {ProcessFilterBar} from '../../../../../../entities/reports/model/process/ProcessFilterBar/ProcessFilterBar';
import {processReportStore} from '../../../../../../entities/reports/model/report.store';
import { StatusFilterBar } from '../../../../../../entities/reports/model/status/StatusFilterBar/StatusFilterBar';
import { StatusReportTable } from '../../../../../../entities/reports/model/status/StatusReportTable/StatusReportTable';

export const StatusReportDashboard = forwardRef(function ProcessReportDashboard(props, ref) {
  useImperativeHandle(ref, () => ({
    refetch: processReportStore.refetch,
  }));
  useEffect(() => {
    processReportStore.onInit();
  }, []);

  return <div {...props} className='status-report-container' ref={ref}>
    <StatusFilterBar />
    <StatusReportTable />
  </div>
})
